import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number, currency: string) {
  return numeral(number).format(`${currency} ${Number.isInteger(number) ? '0,0' : '0,0.00'}`);
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function round(number: number, digits?: number) {
  const rounding = Math.pow(10, digits || 2)
  return Math.round(number * rounding) / rounding
}