// @mui
import { IconButton } from '@mui/material'
import {
  cloneElement,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
} from 'react'
//
import Iconify from '../Iconify'
import MenuPopover from '../MenuPopover'
import compact from 'lodash/compact'

// ----------------------------------------------------------------------
export type ActionElement = ReactElement<any, string | JSXElementConstructor<any>> | null
// | ReactFragment
// | ReactPortal

export type TableMoreMenuActions = ActionElement[] | ActionElement | null | undefined

type Props = {
  actions?: TableMoreMenuActions
  open?: HTMLElement | null
  onClose?: VoidFunction
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void
}

export default function TableMoreMenu({ actions, open, onClose, onOpen }: Props) {
  if (!actions) {
    return null
  }

  const $actions = compact(Array.isArray(actions) ? actions : [actions])

  return (
    <>
      <IconButton onClick={onOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {$actions.map((action, index) =>
          !onClose
            ? action
            : cloneElement(action, {
                key: `action-${index}`,
                onClick: () => onClose(),
              })
        )}
      </MenuPopover>
    </>
  )
}
